/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .user-dashboard .background {
        background: url(./images/lantern_drawing.png) no-repeat;
        background-size: contain;
        position: fixed;
        width: 100%;
        height: 100%;
        top: -100px;
        right: -100px;
        opacity: 0.15;
    }

    .nav-background {
        position: absolute;
        width: 100%;
        height: 90%;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: #FFFFFF;
    }

    .navbar .nav-item {
        width: 70px;
    }

    .nav-item a.active {
        text-decoration: none;
        color: #F87A15 !important;
    }

    .dashboard-heading {
        margin: 50px 0 0 !important;
    }

    .dashboard-subheading {
        margin: 0 0 50px 0 !important;
    }

    .user-dashboard {
        padding: 0 15px !important;
        margin-top: 0 !important;
    }

    .user-dashboard .left-column {
        padding: 0 20px !important;
    }

    .user-dashboard .right-column {
        padding-left: 0 !important;
        border-left: none !important;
    }

    .gathering-info h3 {
        color: #657CB4 !important;
    }

    .create-lantern-button{
        margin-top: 0px;
        margin-right: 0px;
        width: 95px;
    }

    .create-lantern-heading {
        margin-bottom: 0px;
    }
    
    .upload-lantern-heading h3{
        font-size: 16px;
    }
    
    .lantern-card {
        border: 0.75px solid #979797;
        border-right: 0;
        border-left: 0;
        border-radius: 0px;
        background-color: #FFFFFF;
        color: #606060;
        font-size: 14px;
        letter-spacing: 0.23px;
        line-height: 19px;
        height: 150px;
    }

    .lantern-view {
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 0;
    }

    .lantern-view .lantern-info {
        text-align: center;
    }

    .lantern-view .lantern-details {
        padding: 0 !important;
    }

    .lantern-transcript-locales-tabs {
        justify-content: center !important;
    }

    .lantern-transcript-locales-tabs a.nav-item {
        margin-top: 0;
        margin-left: 15px;
        padding-top: 3px;
    }

    .video-js {
        overflow: hidden;
    }

    .navbar-create-count {
        position: absolute;
        top: 16px;
        display: block;
        font-size: 25px;
        width: 70px;
    }

    .navbar-create-count.active {
        color: #FFFFFF;
    }
}
