a:hover {
    text-decoration: none !important;
}

.btn-primary {
    border-radius: 20px;
    background: linear-gradient(0deg, #FF9190 0%, #FFA58C 100%);
    color: #FFFFFF;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 17px;
    border: none;
}

.btn-secondary {
    border-radius: 22px;
    background-color: #7182CE;
    border: none;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
}

.lantern-wide-button {
    min-width: 150px;
}

.lantern-command-button {
    text-transform: uppercase;
}

h3 {
    color: #606060;
    font-size: 15px;
    letter-spacing: 0.25px;
    line-height: 18px;
}

.logo {
    color: black;
    text-decoration: none;
    font-weight: bold;
    font-family: 'Caveat', cursive;
    font-size: 2rem;
}

.logo-dot {
    color: #FF8127;
}

.drop-shadow {
    box-shadow: 2px 3px 8px 0 rgba(0,0,0,0.1);
}

header {
    position: relative;
    background-color: black;
    height: 100vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
    color: white;
}

header .btn-outline-primary {
    color: white !important;
}

header .logo {
    color: white;
}

header video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

header .container {
    position: relative;
    z-index: 2;
}

header nav {
    position: absolute !important;
    top: 0;
    width: 100%;
    z-index: 100;
}

header a.nav-link {
    color: white !important;
    text-decoration: none;
}

header a.nav-link:hover {
    text-decoration: underline;
}

header .sign-in input {
    border-radius: 0 50rem 50rem 0 !important;
    border-color: white;
    border-left: none !important;
    background-color: rgba(0, 0, 0, 0.5);
    color: white !important;
}

header .sign-in input::placeholder {
    color: white;
}

header .sign-in input:focus,
header .sign-in input:-webkit-autofill,
header .sign-in input:-webkit-autofill:hover,
header .sign-in input:-webkit-autofill:focus,
header .sign-in input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px black inset !important;
    -webkit-text-fill-color: white !important;
    background-color: rgba(0, 0, 0, 0.5);
}

header .sign-in .input-group-text {
    border-radius: 50rem 0 0 50rem !important;
    border-right: none !important;
    border-color: white;
    background-color: rgba(0, 0, 0, 0.5);
}

.lantern-desktop-navbar {
    background-color: #FFFFFF;
    height: 80px;
}

.lantern-desktop-navbar .nav-item {
    margin: 0 15px;
    font-weight: bold;
    font-size: 15px;
}

.lantern-desktop-navbar .nav-item a {
    color: #3A3A3A !important;
}

.lantern-desktop-navbar .nav-item a:hover {
    text-decoration: none;
}

.lantern-desktop-navbar .nav-item a.active {
    border-bottom: 3px solid #747ad0 !important;
}

.navbar.lantern-mobile a {
    font-size: 12px;
    color: #3A3A3A !important;
    letter-spacing: 0.2px;
    font-weight: bold;
}

.header-link {
    font-size: 25px;
    font-weight: bold;
    color: #3A3A3A;
    letter-spacing: 0.42px;
    line-height: 30px;
}

.header-link:hover {
    color: #3A3A3A;
    text-decoration: none;
}

.flip-image {
    transform: rotate(180deg);
}

.page-heading {
    background-color: #FFFFFF;
    position: relative;
}

.page-heading h2 {
    margin: 0;
}

.gathering-card-sm {
    background-color: #747AD0;
    border: 0.75px solid rgba(144,144,144,0.78);
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.23px;
    line-height: 17px;
}

.gathering-card-sm a {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
}

.gathering-card-sm a:hover {
    text-decoration: none;
}

.gathering-card-sm hr {
    height: 1px;
    border: none;
    background-color: #FFFFFF;
}

.gathering-card-sm .gathering-stats {
    text-transform: uppercase;
    font-size: 11px;
}

.gathering-card {
    border: 0.75px solid #979797;
    border-radius: 10px;
    background-color: #FFFFFF;
    text-align: center;
    color: #3A3A3A;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.33px;
    line-height: 24px;
    margin-bottom: 0.5em !important;
}

.gathering-card a {
    color: #3A3A3A;
    margin-top: 100em;
}

.gathering-card a:hover {
    text-decoration: none;
}

.gathering-card hr {
    height: 1px;
    width: 85%;
    border: none;
    margin-top: 1.2em;
    background-color: rgba(151,151,151,0.5);
}

.gathering-card .card-body{
    padding: 0.1em !important;
}

.gathering-card-question {
    margin-top: 1em;
}

.gathering-list-tabs {
    border: none !important;
}

.gathering-list-tabs a.nav-item {
    background-color: transparent !important;
    border: none !important;
    margin-top: 10px;
    margin-left: 10px;
    padding: 3px 0;
    color: #747ad0 !important;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: 23px;
}

.gathering-list-tabs a.nav-item.active {
    border-bottom: 3px solid #747ad0 !important;
}

.gathering-list{
    margin-top: 0em !important;
}

.gathering-info {
    background-color: #FFFFFF;
    text-align: center;
}

.gathering-info h3 {
    color: #3A3A3A;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.33px;
    line-height: 24px;
}

.gathering-lantern-tabs {
    border: none !important;
}

.gathering-lantern-tabs a.nav-item {
    background-color: transparent !important;
    border: none !important;
    margin: 10px 20px;
    padding: 3px 0;
    color: #747ad0 !important;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: 23px;
    min-width: 100px;
    white-space: nowrap;
}

.gathering-lantern-tabs a.nav-item.active {
    border-bottom: 3px solid #747ad0 !important;
}

.lantern-card {
    border-radius: 8px;
    background-color: #FFFFFF;
}

.lantern-card a:hover {
    text-decoration: none;
}

.lantern-card-details {
    color: #606060;
    font-size: 16px;
    letter-spacing: 0.27px;
    line-height: 18px;
}

.lantern-view {
    border: 0.75px solid #979797;
    border-radius: 30px;
    background-color: #FFFFFF;
    padding: 20px;
    margin: 50px;
}

.lantern-view .lantern-info {
    text-align: left;
}

.lantern-view .lantern-details {
    padding: 0 0 0 25px !important;
}

.lantern-info {
    color: #657CB4;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.33px;
    line-height: 26px;
    padding-left: 0;
    padding-right: 0;
}

.lantern-video {
    background-color: #FFFFFF;
}

.lantern-transcript-tabs {
    border: none !important;
}

.lantern-transcript-tabs .nav-item {
    margin-bottom: 8px !important;
}

.lantern-transcript-tabs a.nav-item {
    background-color: transparent !important;
    border: none !important;
    margin: 0 20px 10px;
    padding: 0 0 3px 0;
    color: #606060;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: 23px;
}

.lantern-transcript-tabs a.nav-item.active {
    border-bottom: 3px solid #747ad0 !important;
}

.lantern-transcript-locales-tabs {
    border: none !important;
}

.lantern-transcript-locales-tabs a.nav-item {
    background-color: transparent !important;
    color: #606060;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: 23px;
    text-align: center;
    border: 1.5px solid #606060 !important;
    border-radius: 15px;
    padding: 2px 15px;
    margin: 15px 15px 0 0;
    width: 125px;
    outline: none;
}

.lantern-transcript-locales-tabs a.nav-item.active {
    background-color: #7182CE !important;
    border: 1.5px solid #7182CE !important;
    color: #FFFFFF;
    outline: none !important;
}

.explore-lantern-tabs {
    border: none !important;
}

.explore-lantern-tabs a.nav-item {
    background-color: transparent !important;
    border: none !important;
    margin: 10px 20px;
    padding: 3px 0 0;
    color: #747ad0 !important;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: 23px;
    min-width: 100px;
    white-space: nowrap;
}

textarea {
    resize: none;
    color: #606060 !important;
}

.choose-question-heading h2 {
    color: #6578B6;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.33px;
    line-height: 26px;
}

.gathering-question-card {
    border: 0.75px solid #979797;
    border-radius: 10px;
    background-color: #FFFFFF;
    cursor: pointer;
}

.gathering-question-card:hover {
    border: 3px solid #6578B6;
}

.gathering-question-card .question {
    color: #6578B6;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 0.25px;
    line-height: 19px;
}

.question-stat {
    color: #979797;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.17px;
    line-height: 13px;
    text-transform: uppercase;
}

.create-gathering-heading h2 {
    color: #3A3A3A;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0.42px;
    line-height: 32px;
}

.create-gathering-details {
    border: 0.75px solid #979797;
    border-radius: 15px;
    background-color: #FFFFFF;
}

.create-gathering-details .participants-heading {
    color: #606060;
    font-size: 15px;
    letter-spacing: 0.25px;
    line-height: 19px;
}

.participant {
    max-width: 35px;
}

.create-gathering-details .participant button {
    width: 35px;
    height: 35px;
}

.create-gathering-details .participant button svg {
    vertical-align: middle;
}

.create-lantern-button{
    margin-top: 15px;
    margin-right: 50px;
    width: 175px;
    float: right;
}

.create-lantern-heading {
    margin-bottom: -100px;
}

.create-lantern-heading h2 {
    color: #3a3a3a;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.42px;
    line-height: 32px;
    font-size: 25px;  
    font-weight: bold; 
}

.upload-lantern-heading h3{
    font-size: 20px;  
    font-weight: bold !important;
    color:#606060;
    margin-bottom: 25px;
}

.upload-button {
    width: 100px;
    height: 100px;
    background-color: #E7E5FE;
    box-shadow: 2px 3px 8px 0 rgba(0,0,0,0.1);
    border-radius: 50%;
    background-image: url('./images/icons/upload.svg');
}

.edit-transcript-heading h2{
    color: #606060 !important;
    font-size: 19px;
    letter-spacing: 0.33px;
    line-height: 26px;
}

.edit-transcript-time-code{
    font-size: 12px;
    font-weight: 500;
    color: #292929;
    margin-bottom: 6px;
}

.lantern-gathering-card {
    background-color: #FFFFFF;
}

.lantern-gathering-card a {
    color: #3A3A3A;
    text-decoration: none;
}

.lantern-gathering-card a:hover {
    text-decoration: none;
}

.lantern-gathering-question {
    color: #6578B6;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 17px;
}

.dashboard-heading {
    font-size: 25px;
    font-weight: bold;
}

.dashboard-subheading {
    margin: 0 0 50px 0;
}

.user-dashboard {
    padding: 0 50px;
    margin-top: 50px !important;
}

.user-dashboard .left-column {
    padding-right: 25px;
}

.user-dashboard .right-column {
    padding-left: 25px !important;
    padding-right: 0 !important;
    border-left: 1px solid rgba(151,151,151,0.5);
}

.profile-placeholder {
    background-color: rgb(216, 216, 216);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    text-align: center;
}

.profile-placeholder span {
    color: rgb(151, 151, 151);
    font-weight: bold;
    line-height: 37px;
}

.lantern-creator {
    color: #606060;
    line-height: 35px;
}

.lantern-creator .profile-placeholder {
    float: left;
    margin-right: 5px;
}

.sticky-video {
    position: sticky;
    top: 0;
    z-index: 100;
}

/* Change the border of the big play button. */

.video-js {
    color: #c7c7c7 !important;
    overflow: hidden;
}

.video-js .vjs-big-play-button {
    border: solid 3px #c4c4c4 !important; 
    background-color: transparent !important;
    color: #c4c4c4 !important;
    border-radius: 50px !important;
    width: 50px !important;
    margin-left: -0.8em !important;
}

.video-js .vjs-control-bar { 
    width: 80% !important;
    bottom: 15px !important;
    margin: auto !important;
    background-color: #333333 !important;
    border-radius: 4.5px !important;
    backdrop-filter: blur(12px) !important;
    -webkit-backdrop-filter: blur(12px) !important;
}

.video-js .vjs-remaining-time-display
.video-js .vjs-volume-level,
.video-js .vjs-slider,
.video-js .vjs-play-progress,
.video-js .vjs-load-progress,
.video-js .vjs-slider-bar {
    background: #c7c7c7 !important;
}

.video-js .vjs-text-track-cue > div{
    background-color:#3b3b3b !important;
    font-family: PTSans-Caption !important;
    letter-spacing: 0.2px !important;
    color: #ffffff !important;
}

footer {
    margin-top: -20px;
}
